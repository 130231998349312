import { fetchUtils } from "react-admin";
import queryString from "query-string";
import { downloadFile } from "./utils/downloadHandler";

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }

    const auth = JSON.parse(localStorage.getItem("auth"));
    options.headers.set('X-RA-USERNAME', auth ? auth.username : '');

    return fetchUtils.fetchJson(url, options);
}
// https://marmelab.com/react-admin/DataProviders.html
// resourceConverters: object with one property per resource like
//     <resourcename>: { "convertIn": (responsedata) => object, "convertOut" (object) => requestObj }
export default class EquipDataprovider {
    constructor(
        apiUrl,
        resourceConverters = {}
    ) {
        this.apiUrl = apiUrl;
        this.resourceConverters = resourceConverters;
        this.appVersion = null
    }

    makePromise(p) {
        if (typeof p === "object" && typeof p.then === "function") {
            return p;
        }
        return new Promise(function (resolve) {
            resolve(p);
        });
    }

    convertIn(resource, response) {
        if (resource in this.resourceConverters) {
            if ("convertIn" in this.resourceConverters[resource]) {
                if (Array.isArray(response.data)) {
                    return response.data.map(
                        this.resourceConverters[resource].convertIn
                    );
                } else {
                    return this.resourceConverters[resource].convertIn(
                        response.data
                    );
                }
            }
        }
        return response.data;
    }

    convertOut(resource, data) {
        if (data.createdBy) {
            delete data.createdBy;
        }
        if (data.createdAt) {
            delete data.createdAt;
        }
        if (data.updatedAt) {
            delete data.updatedAt;
        }
        if (data.id) {
            delete data.id;
        }

        if (resource in this.resourceConverters) {
            if ("convertOut" in this.resourceConverters[resource]) {
                return this.makePromise(
                    this.resourceConverters[resource].convertOut(data)
                );
            }
        }
        return this.makePromise(data);
    }

    // params: { target: {string}, id: {mixed}, pagination: { page: {int} , perPage: {int} }, sort: { field: {string}, order: {string} }, filter: {Object} }
    prepareQuery(params) {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const rangeStart = (page - 1) * perPage;
        let filters = params.filter;
        if ("target" in params) {
            filters[params.target] = params.id;
        }

        return {
            _limit: perPage,
            _offset: rangeStart,
            _sort: field + "," + order,
            ...filters,
        };
    }

    // params: { embed: {?string} }
    addEmbed(url, params) {
        if (params?.embed) {
            if (url.includes("?")) {
                return url + "&_embed=" + params.embed;
            }

            return url + "?_embed=" + params.embed;
        }

        return url;
    }

    emptyObj(obj) {
        return (
            obj &&
            Object.keys(obj).length === 0 &&
            Object.getPrototypeOf(obj) === Object.prototype
        );
    }

    checkAppVersion(headers) {
        const responseAppVersion = headers.get('X-APP-VERSION');
        if (null === this.appVersion) {
            this.appVersion =responseAppVersion;
        } else if (responseAppVersion !== this.appVersion) {
            console.info('App version changed, reloading page');
            window.location.reload();
        }
    }

    getList(resource, params) {
        const query = this.prepareQuery(params);
        const url = `${this.apiUrl}/${resource}?${queryString.stringify(query, {arrayFormat: "bracket"})}`;
        const options = {};
        return httpClient(this.addEmbed(url, params), options).then(({ headers, json }) => {
            this.checkAppVersion(headers);
            let res = this.convertIn(resource, json);
            if (this.emptyObj(res)) {
                res = [];
            }
            return {
                data: res,
                total: json.meta.total_rows,
            };
        });
    }

    getMultiList(resources, params) {
        const query = this.prepareQuery(params);
        const requests = {};

        for (let resource of resources) {
            requests[resource] = {
                uri: `${this.apiUrl}/${resource}?${queryString.stringify(query, {arrayFormat: "bracket"})}`,
                body: null,
                method: "GET",
            };
        }

        return httpClient(`${this.apiUrl}/api/multi`, {
            method: "POST",
            body: JSON.stringify(requests),
        }).then(({ headers, json }) => {
            const responses = {};
            for (let resource of resources) {
                let res = this.convertIn(resource, json[resource]);
                if (this.emptyObj(res)) {
                    res = [];
                }
                responses[resource] = {
                    data: res,
                    total: json[resource].meta.total_rows,
                };
            }

            return responses;
        });
    }

    getOne(resource, params) {
        return httpClient(
            this.addEmbed(`${this.apiUrl}/${resource}/${params.id}`, params.meta)
        ).then(({ json }) => ({
            data: this.convertIn(resource, json),
        }));
    }

    getMany(resource, params) {
        const ids = params.ids.map((id) => {
            return id.id ?? id;
        });

        const query = {
            id: JSON.stringify(ids),
        };
        const url = `${this.apiUrl}/${resource}?${queryString.stringify(query)}`;
        return httpClient(url).then(({ json }) => ({
            data: this.convertIn(resource, json),
        }));
    }

    getManyReference(resource, params) {
        // Extract the @@record key and name the variable for its content "record"
        // You now have access to the record that reference the targeted resource
        const { "@@record": record, ...filter } = params.filter;

        if (
            record != null &&
            record._embedded != null &&
            record._embedded[resource] != null
        ) {
            return Promise.resolve({
                data: record._embedded[resource],
                total: record._embedded[resource].length,
            });
        }

        if (
            record != null &&
            record._links != null &&
            record._links[resource] != null
        ) {
            return httpClient(record._links[resource]).then(
                ({ headers, json }) => {
                    let res = this.convertIn(resource, json);
                    if (this.emptyObj(res)) {
                        res = [];
                    }
                    return {
                        data: res,
                        total: json.meta.total_rows,
                    };
                }
            );
        }

        const sanitizedParams = { ...params, filter };
        const query = this.prepareQuery(sanitizedParams);
        const url = `${this.apiUrl}/${resource}?${queryString.stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => {
            let res = this.convertIn(resource, json);
            if (this.emptyObj(res)) {
                res = [];
            }
            return {
                data: res,
                total: json.meta.total_rows,
            };
        });
    }

    update(resource, params) {
        return this.convertOut(resource, params.data).then((data) =>
            httpClient(`${this.apiUrl}/${resource}/${params.id}`, {
                method: "PATCH",
                body: JSON.stringify(data),
            }).then(({ json }) => {
                return httpClient(`${this.apiUrl}${json.url}`).then(
                    ({ json }) => {
                        const res = this.convertIn(resource, json);
                        return { data: res };
                    }
                );
            })
        );
    }

    async updateMany(resource, params) {
        const body = JSON.stringify(
            await this.convertOut(resource, params.data)
        );
        const requests = [];

        for (let id of params.ids) {
            requests.push({
                uri: `${this.apiUrl}/${resource}/${id}`,
                method: "PATCH",
                body: body,
            });
        }

        return httpClient(`${this.apiUrl}/api/multi`, {
            method: "POST",
            body: JSON.stringify(requests),
        }).then(({ json }) => ({ data: json }));
    }

    create(resource, params) {
        return this.convertOut(resource, params.data).then((data) =>
            httpClient(`${this.apiUrl}/${resource}`, {
                method: "POST",
                body: JSON.stringify(data),
            }).then(({ json }) =>
                httpClient(
                    this.addEmbed(`${this.apiUrl}${json.url}`, params)
                ).then(({ json }) => ({ data: this.convertIn(resource, json) }))
            )
        );
    }

    async createMany(resource, params) {
        const requests = [];
        for (let data of params.data) {
            const body = JSON.stringify(
                await this.convertOut(resource, data)
            );

            requests.push({
                uri: `${this.apiUrl}/${resource}`,
                method: "POST",
                body: body,
            });
        }

        return httpClient(`${this.apiUrl}/api/multi`, {
            method: "POST",
            body: JSON.stringify(requests),
        }).then(({ json }) => ({ data: json }));
    }

    delete(resource, params) {
        return httpClient(`${this.apiUrl}/${resource}/${params.id}`, {
            method: "DELETE",
        }).then(() => ({ data: params.previousData }));
    }

    deleteMany(resource, params) {
        const requests = [];
        for (let id of params.ids) {
            requests.push({
                uri: `${this.apiUrl}/${resource}/${id}`,
                method: "DELETE",
                body: null,
            });
        }

        return httpClient(`${this.apiUrl}/api/multi`, {
            method: "POST",
            body: JSON.stringify(requests),
        });
    }

    ///////////////// END Standard Functions ///////////////////////

    /*
     * params:
     *   id: id of resource to send action to
     *   actionName: action to call
     *   data: data to pass to action (in json body)
     *
     *  is not called by the framework, must be called directly with provider injected by useDataprovider.
     * */
    performAction(resource, params) {
        return httpClient(
            this.addEmbed(
                `${this.apiUrl}/${resource}/${params.id}?action=${params.actionName}`,
                params
            ),
            {
                method: "POST",
                body: JSON.stringify(params.data),
            }
        ).then(({ json }) => ({
            data: this.convertIn(resource, json),
        }));
    }

    getDownload(resource, params) {
        return downloadFile(
            `${this.apiUrl}/${resource}/${params.id}`,
            params.contentType
        );
    }

    get(uri, resource) {
        return httpClient(
            `${this.apiUrl}${uri}`
        ).then(({ json }) => ({
            data: this.convertIn(resource, json),
        }));
    }

    /* ----------------------------- categories tree ---------------------------- */

    getTree(resource) {
        const url = `${this.apiUrl}/${resource}`;
        return httpClient(this.addEmbed(url, { embed: "children" })).then(
            ({ headers, json }) => {
                const res = this.convertIn(resource, json);
                const data = res.map((category) => {
                    return {
                        id: category.id,
                        name: category.name,
                        children: category.children.map((child) => child.id),
                    };
                });
                return { data };
            }
        );
    }

    getRootNodes(resource) {
        const url = `${this.apiUrl}/${resource}`;
        return httpClient(
            this.addEmbed(`${url}?parent=null`, { embed: "children" })
        ).then(({ headers, json }) => {
            const res = this.convertIn(resource, json);
            const data = res.map((category) => {
                return {
                    id: category.id,
                    name: category.name,
                    children: category.children.map((child) => child.id),
                };
            });
            return { data };
        });
    }

    getParentNode(resource, { childId }) {
        const url = `${this.apiUrl}/${resource}/${childId}`;
        return httpClient(this.addEmbed(url, { embed: "parent" }))
            .then(({ headers, json }) => {
                const res = this.convertIn(resource, json);
                if (!res.parent) {
                    return Promise.reject("no parent");
                }
                return res.parent.id;
            })
            .then((parentId) => {
                return httpClient(
                    this.addEmbed(`${this.apiUrl}/${resource}/${parentId}`, {
                        embed: "children",
                    })
                ).then(({ headers, json }) => {
                    const res = this.convertIn(resource, json);

                    const data = {
                        id: res.id,
                        name: res.name,
                        children: res.children.map((child) => child.id),
                    };
                    return { data };
                });
            });
    }

    getChildNodes(resource, { parentId }) {
        const url = `${this.apiUrl}/${resource}?parent=${parentId}`;
        return httpClient(this.addEmbed(url, { embed: "children" })).then(
            ({ headers, json }) => {
                if (this.emptyObj(json.data)) {
                    return { data: [] };
                }
                const res = this.convertIn(resource, json);
                const data = res.map((category) => {
                    return {
                        id: category.id,
                        name: category.name,
                        children: category.children.map((child) => child.id),
                    };
                });
                return { data };
            }
        );
    }

    deleteBranch(resource, { id, data }) {
        return httpClient(`${this.apiUrl}/${resource}/${id}`, {
            method: "DELETE",
        }).then(({ json }) => ({ data: { result: "deleted" } }));
    }

    addRootNode(resource, { data }) {
        return this.convertOut(resource, data).then((converted_data) =>
            httpClient(`${this.apiUrl}/${resource}`, {
                method: "POST",
                body: JSON.stringify(converted_data),
            }).then(({ json }) => {
                const resData = {
                    id: Number(json.url.split("/")[2]),
                    name: data.name,
                    children: [],
                };
                return { data: resData };
            })
        );
    }

    addChildNode(resource, { parentId, data }) {
        return this.convertOut(resource, { ...data, parent: parentId }).then(
            (converted_data) =>
                httpClient(`${this.apiUrl}/${resource}`, {
                    method: "POST",
                    body: JSON.stringify(converted_data),
                }).then(({ json }) => {
                    const resData = {
                        id: Number(json.url.split("/")[2]),
                        name: data.name,
                        children: [],
                    };
                    return { data: resData };
                })
        );
    }
}
