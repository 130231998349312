import dayjs from 'dayjs';

const productConverter = {
    convertIn: (responseData) => {
        if (responseData.tags) {
            responseData.tags = responseData.tags.map((e) => e.id);
        }

        if (responseData.name && typeof responseData.name === 'string') {
            responseData.name = JSON.parse(responseData.name);
            !responseData.name.de && (responseData.name.de = '');
            !responseData.name.en && (responseData.name.en = '');
        }

        if (responseData.description && typeof responseData.description === 'string') {
            responseData.description = JSON.parse(responseData.description);
            !responseData.description.de && (responseData.description.de = '');
            !responseData.description.en && (responseData.description.en = '');
        }

        if (responseData.createdAt) {
            responseData.createdAt = dayjs(responseData.createdAt);
        }

        if (responseData.updatedAt) {
            responseData.updatedAt = dayjs(responseData.updatedAt);
        }

        return responseData;
    },
    convertOut: (object) => {
        if (!object.isMassProduct) {
            delete object.labelNumber;
        }

        delete object.id;

        return {
            ...object,
            ...(object?.name && { name: JSON.stringify(object.name) }),
            ...(object?.description && { description: JSON.stringify(object.description) }),
            ...(object?.category && { category: object.category ? object.category.id : null }),
            ...(object?.manufacturer && { manufacturer: object.manufacturer ? object.manufacturer.id : null }),
            ...(object?.supplier && { supplier: object.supplier ? object.supplier.id : null }),
        };
    },
};

export default productConverter;
