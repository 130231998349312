import {
    Form,
    useTranslate,
    NumberInput,
    useRecordContext,
    useNotify,
} from "react-admin";
import { Button, Box } from "@mui/material";
import { ConfirmationDialog } from "shared/src/components/dialogs/ConfirmationDialog";
import { RaSelectInput } from "./inputs/Ra/RaSelectInput";

export const PrintLabels = (props) => {
    const translate = useTranslate();
    const record = useRecordContext();
    const notify = useNotify();

    const print = (data) => {
        const route = props.arrivalData
            ? props.arrivalData._links.label_pdf
            : record._links.label_pdf;

        fetch(route, {
            method: "POST",
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (response.status < 200 || response.status >= 400) {
                    throw new Error(response.statusText);
                }
                notify("equip.labelPrintJobPlaced", {
                    type: "success",
                });
                props.setPrintLabels(false);
            })
            .catch(() => {
                notify("equip.labelPrintJobNotPlaced", {
                    type: "error",
                });
            });
    };

    return (
        <ConfirmationDialog
            label={translate("equip.printLabels")}
            confirmButton={false}
            cancelButton={false}
            content={
                <Form
                    onSubmit={print}
                    record={{ quantity: 1, type: "barcode" }}
                >
                    <NumberInput
                        fullWidth
                        label="equip.labelQuantity"
                        title={translate("equip.labelQuantity")}
                        source="quantity"
                        min={1}
                    />
                    <RaSelectInput
                        fullWidth
                        required
                        source="type"
                        choices={[
                            { id: "barcode", name: "Barcode" },
                            { id: "qr", name: "QR" },
                        ]}
                    />
                    <Box display={"flex"} justifyContent={"space-between"}>
                        <Button
                            variant="contained"
                            type="submit"
                            title={translate("equip.print")}
                            aria-label={translate("equip.print")}
                        >
                            {translate("equip.print")}
                        </Button>
                        <Button
                            onClick={() => {
                                props.setPrintLabels(false);
                            }}
                            title={translate("equip.close")}
                            aria-label={translate("equip.close")}
                        >
                            {translate("equip.close")}
                        </Button>
                    </Box>
                </Form>
            }
        />
    );
};
