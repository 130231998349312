import React, { useCallback, useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/de";
import { useLocaleState, useTranslate } from "react-admin";
import { ConfirmationDialog } from "shared/src/components/dialogs/ConfirmationDialog";

export const TimeframeDatepicker = ({ startDate, endDate, updateUrl, period }) => {
    const [timeframeWarning, setTimeframeWarning] = useState(null);
    const translate = useTranslate();
    const [locale] = useLocaleState();

    const handleStartDate = (newDate) => {
        timeframeCheck(newDate, endDate);
    };

    const handleEndDate = (newDate) => {
        timeframeCheck(startDate, newDate);
    };

    const timeframeCheck = useCallback(
        (startDate, endDate) => {
            const timeframeInMs = endDate.diff(startDate);
            const timeframeInDays = endDate.diff(startDate, "day");

            if (timeframeInMs < 0) {
                setTimeframeWarning("equip.timeframeWarning_start_date_after_end_date");
                return;
            }
            if (timeframeInDays > 60) {
                setTimeframeWarning("equip.timeframeWarning_timeframe_too_wide");
                return;
            }
            updateUrl(startDate, endDate);
        },
        [updateUrl]
    );

    const setDateToToday = () => {
        const newEndDate = dayjs().add(period, "day");
        updateUrl(dayjs(), newEndDate);
    };

    useEffect(() => {
        timeframeCheck(startDate, endDate);
    }, [startDate, endDate, timeframeCheck]);

    return (
        <Box marginTop={3} marginBottom={3} display="flex" justifyContent="left">
            {timeframeWarning && (
                <ConfirmationDialog
                    cancelButton={false}
                    onConfirm={() => setTimeframeWarning(false)}
                    label={translate("equip.timeframeWarning")}
                    content={translate(timeframeWarning || "")}
                    confirmLabel={translate("equip.proceed")}
                />
            )}
            <Box sx={{ width: 160 }} marginTop={3} marginLeft={9} marginRight={1}>
                <LocalizationProvider
                    inputVariant={"outlined"}
                    dateAdapter={AdapterDayjs}
                    adapterLocale={locale}
                >
                    <DesktopDatePicker
                        label={translate("equip.startDate")}
                        value={startDate}
                        onChange={(newDate) => handleStartDate(newDate)}
                    />
                </LocalizationProvider>
            </Box>
            <Box marginTop={4} marginRight={1}>
                <Button
                    onClick={setDateToToday}
                    variant="contained"
                    title={translate("equip.today")}
                    aria-label={translate("equip.today")}
                >
                    {translate("equip.today")}
                </Button>
            </Box>
            <Box sx={{ width: 160 }} marginTop={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                    <DesktopDatePicker
                        label={translate("equip.endDate")}
                        value={endDate}
                        onChange={(newDate) => handleEndDate(newDate)}
                    />
                </LocalizationProvider>
            </Box>
        </Box>
    );
};
