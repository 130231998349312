import { Typography, Card, CardContent, Box, Button } from "@mui/material";
import {
    useDataProvider,
    useNotify,
    usePermissions,
    useRecordContext,
    useResourceContext,
    useTranslate,
} from "react-admin";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import InfoIcon from "@mui/icons-material/Info";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import DownloadIcon from "@mui/icons-material/GetApp";
import WarningIcon from "@mui/icons-material/Warning";
import RentalClose from "./RentalClose";
import RentalDunning from "./RentalDunning";
import React, { useState } from "react";
import { handleHttpError } from "shared/src/utils/errorHandler";
import { EditReturnDateDialog } from "shared/src/components/dialogs/EditReturnDateDialog";
import RestoreIcon from "@mui/icons-material/Restore";

export const RentalItems = ({ rentalItems }) => {
    const [returnDateDialog, setReturnDateDialog] = useState(false);
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const rental = useRecordContext();
    const resource = useResourceContext();
    const { permissions } = usePermissions();
    const notify = useNotify();

    const renderDownloadRentalPdfButton =
        rental &&
        rental.id &&
        permissions.vote({
            resource,
            attribute: "read",
            subject: rental,
        });

    const renderDownloadCarnetButton =
        rental &&
        rental.id &&
        permissions.vote({
            resource,
            attribute: "read",
            subject: rental,
        }) &&
        rentalItems?.length > 0;

    const renderChangeReturnDateForAllItemsButton =
        rental?.state !== "closed" &&
        permissions.vote({
            resource,
            attribute: "edit",
            subject: rental,
        }) &&
        rentalItems?.length > 0;

    const renderFinalizeRentalButton = [
        "open",
        "partially_returned",
        "returned",
    ].includes(rental?.state);

    const handleReturnDateDialog = () => {
        setReturnDateDialog(true);
    };

    return (
        <Box m={1}>
            <EditReturnDateDialog
                open={returnDateDialog}
                onClose={() => setReturnDateDialog(false)}
                onSettled={() => {
                    setReturnDateDialog(false);
                }}
                rental={rental}
                onError={(e) => handleHttpError(e, notify)}
            />
            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Rental Item
                        {renderDownloadRentalPdfButton && (
                            <Button
                                onClick={() =>
                                    dataProvider.getDownload("rentals", {
                                        id: rental.id,
                                        contentType: "application/pdf",
                                    })
                                }
                                title={translate("equip.downloadPdf")}
                                label={translate("equip.downloadPdf")}
                                color={"primary"}
                                startIcon={<DownloadIcon />}
                            >
                                {translate("equip.downloadPdf")}
                            </Button>
                        )}
                        {renderDownloadCarnetButton && (
                            <Button
                                onClick={() =>
                                    dataProvider.getDownload("rentals", {
                                        id: rental.id,
                                        contentType:
                                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                    })
                                }
                                title={translate("equip.downloadCarnet")}
                                label={translate("equip.downloadCarnet")}
                                color={"primary"}
                                startIcon={<DownloadIcon />}
                            >
                                {translate("equip.downloadCarnet")}
                            </Button>
                        )}
                        {renderChangeReturnDateForAllItemsButton && (
                            <Button
                                startIcon={<RestoreIcon />}
                                onClick={handleReturnDateDialog}
                                title={translate(
                                    "equip.changeReturnDateForAllItems"
                                )}
                                label={translate(
                                    "equip.changeReturnDateForAllItems"
                                )}
                            >
                                {translate("equip.changeReturnDateForAllItems")}
                            </Button>
                        )}
                        {renderFinalizeRentalButton &&
                            permissions.vote({
                                resource,
                                attribute: "edit",
                                subject: rental,
                            }) && <RentalClose rental={rental} />}
                    </Typography>

                    <Box display="flex">
                        <Box flexGrow={1}>
                            <Box display="flex" mb="1em">
                                <Box mr="1em">
                                    <InfoIcon
                                        fontSize="small"
                                        color="disabled"
                                    />
                                </Box>
                                <Box flexGrow={1}>
                                    <Typography>
                                        {translate("equip.name")}
                                    </Typography>
                                    <Typography variant="body2">
                                        {rental.name}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display="flex">
                                <Box mr="1em">
                                    <AccessTimeIcon
                                        fontSize="small"
                                        color="disabled"
                                    />
                                </Box>
                                <Box flexGrow={1}>
                                    <Typography>
                                        {translate("equip.startDate")}
                                    </Typography>
                                    <Typography variant="body2">
                                        {new Date(
                                            rental.startDate
                                        ).toLocaleDateString()}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box flexGrow={1}>
                            <Box display="flex" mb="1em">
                                <Box mr="1em">
                                    <FlashOnIcon
                                        fontSize="small"
                                        color="disabled"
                                    />
                                </Box>
                                <Box flexGrow={1}>
                                    <Typography>
                                        {translate("equip.state")}
                                    </Typography>
                                    <Typography variant="body2">
                                        {translate("equip." + rental.state)}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display="flex">
                                <Box mr="1em">
                                    <AccessTimeIcon
                                        fontSize="small"
                                        color="disabled"
                                    />
                                </Box>
                                <Box flexGrow={1}>
                                    <Typography>
                                        {translate("equip.endDate")}
                                    </Typography>
                                    <Typography variant="body2">
                                        {new Date(
                                            rental.endDate
                                        ).toLocaleDateString()}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        {(rental.daysOverdue > 0 ||
                            null !== rental.dunning) && (
                            <Box flexGrow={1}>
                                <Box display="flex" mb="1em">
                                    <Box mr="1em">
                                        <AccessAlarmIcon
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography>
                                            {translate("equip.daysOverdue")}
                                        </Typography>
                                        <Typography variant="body2">
                                            {rental.daysOverdue}{" "}
                                            {translate(
                                                "equip." +
                                                    (rental.daysOverdue === 1
                                                        ? "day"
                                                        : "days")
                                            )}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" mb="1em">
                                    <Box mr="1em">
                                        <WarningIcon
                                            fontSize="small"
                                            color="disabled"
                                        />
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography>
                                            {translate("equip.dunning.level")}
                                        </Typography>
                                        <Typography variant="body2">
                                            {translate(
                                                "equip.dunning.level_" +
                                                    (rental.dunning
                                                        ? rental.dunning.level
                                                        : 0)
                                            )}
                                            {null !==
                                                rental.daysOverdueDunning && (
                                                <>
                                                    &nbsp;(
                                                    {
                                                        rental.daysOverdueDunning
                                                    }{" "}
                                                    {translate(
                                                        "equip." +
                                                            (rental.daysOverdueDunning ===
                                                            1
                                                                ? "day"
                                                                : "days")
                                                    )}
                                                    )
                                                </>
                                            )}
                                            &nbsp;
                                            {permissions.vote({
                                                resource,
                                                attribute: "dunning",
                                                subject: rental,
                                            }) && (
                                                <RentalDunning
                                                    rental={rental}
                                                />
                                            )}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default RentalItems;
